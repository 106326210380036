'use strict';

// Pull in the styles ...
import './main/scss/main.scss';

import React from 'react';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import App from "@uw-it-sis/lib-react/lib/App";
import history from '@uw-it-sis/lib-react/lib/History';
import {render} from '@uw-it-sis/lib-react/lib/Render';

import Header from '@uw-it-sis/lib-react-myplan/lib/Header';
import Footer from '@uw-it-sis/lib-react-myplan/lib/Footer';
import AppNavigation from '@uw-it-sis/lib-react-myplan/lib/AppNavigation';
import {MYPLAN_GEN_ED_ANNOUNCE} from "@uw-it-sis/lib-react-myplan/lib/Constants";

import Home from './main/js/home/Home';
import Account from './main/js/account/Account';

/**
 * Define the routes.
 * https://stackoverflow.com/questions/33996484/using-multiple-layouts-for-react-router-components
 */
/**
 * Put the AppStore in the React context so that it's available to any class that needs it.
 */
const router = (
    <AppContext.Provider value={{
        appStore: appStore,
        history: history
    }}>
        <HashRouter>
            <Routes>
                <Route path="account" element={
                    <App
                        name="home"
                        title="UW MyPlan"
                        routes={<Account />}
                        header={<Header />}
                        footer={<Footer />}
                        navigation={<AppNavigation />}
                        bannerModal={MYPLAN_GEN_ED_ANNOUNCE}
                    />
                } />
                <Route path="" element={
                    <App
                        name="home"
                        title="UW MyPlan"
                        routes={<Home />}
                        showAppNavigation={false}
                        header={<Header showAppNavigation={false} />}
                        footer={<Footer />}
                        bannerModal={MYPLAN_GEN_ED_ANNOUNCE}
                    />
                } />
                <Route path="home" element={<Navigate to="/" replace={true} />} />
            </Routes>
        </HashRouter>
    </AppContext.Provider>
);

render(router);